// Api
export const BANNERS = "banners";
export const BRANDS = "brands";
export const CATEGORIES = "categories";
export const COUPONS = "coupons";
export const COURSES = "courses";
export const DOCUMENTS = "documents";
export const LECTURES = "lectures";
export const PRODUCT_GROUPS = "product-groups";
export const PRODUCTS = "products";
export const PURCHASES = "purchases";
export const QUOTES = "quotes";
export const RESOURCES = "resources";
export const SELLER_ADDRESSES = "seller-addresses";
export const SELLER_BILLING_ADDRESSES = "seller-billing-addresses";
export const SELLERS = "sellers";
export const SUPPLIER_ADDRESSES = "supplier-addresses";
export const SUPPLIER_PURCHASES = "supplier-purchases";
export const SUPPLIERS = "suppliers";
export const SUPPLIER_PAYMENTS = "suppliers-payments";
export const SUPPLIER_TRANSACTIONS = "suppliers-transactions";
export const TICKETS = "tickets";
export const VOUCHERS = "vouchers";
export const OFFERTS = "offerts";

// Firestore
export const ADMINS = "Admins";
export const ADMINROLES = "AdminRoles";

// Source code
export const PERMISSIONS = "Permissions";
export const SUPPLIER_PERMISSIONS = "supplier-permissions";
