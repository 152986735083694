import { getInstance } from "../../core/axios-gateway";
import { TClient, TRequestClient } from "../../types/client";

const BASE_PATH = "/api/clients";

export const getClientsBySellerId = async (sellerId: string) => {
  const range: string = "[0,100]";
  return getInstance()
    .get<TClient[]>(`${BASE_PATH}/${sellerId}`, {
      headers: {
        range,
      },
    })
    .then((res) => res.data);
};

export const createClientBySellerId = async (
  sellerId: string,
  data: TRequestClient
) => {
  return getInstance()
    .post<TClient>(`${BASE_PATH}/${sellerId}`, data)
    .then((res) => res.data);
};

export const updateClientBySellerAndClientId = async (
  sellerId: string,
  data: TClient
) => {
  const { id: clientId } = data;
  return getInstance()
    .put<TClient>(`${BASE_PATH}/${sellerId}/${clientId}`, data)
    .then((res) => res.data);
};

export const deleteClientBySellerAndClientId = async (
  sellerId: string,
  clientId: string
) => {
  return getInstance()
    .delete<TClient>(`${BASE_PATH}/${sellerId}/${clientId}`)
    .then((res) => res.data);
};
