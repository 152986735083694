import { getInstance } from "../../core/axios-gateway";
import {
  TLecture,
  TLectureResourcePayload,
  TPartialLecturePayload,
} from "../../types/education/lecture";
import { TRestListData, TRestListParams } from "../../types/rest";

const BASE_PATH = "/api/education/course";
const LECTURE_PATH = "/api/education/lecture";

export const getLectures = async (
  params: TRestListParams,
  courseId: string
) => {
  return getInstance()
    .get<TRestListData<TLecture>>(`${BASE_PATH}/${courseId}/lecture/list`, {
      params,
    })
    .then((res) => res.data);
};

export const getLecture = async (lectureId: string, courseId: string) => {
  return getInstance()
    .get<TLecture>(`${BASE_PATH}/${courseId}/lecture/${lectureId}`)
    .then((res) => res.data);
};

export const createLectureResource = async (
  data: TLectureResourcePayload,
  lectureId: string
) => {
  return getInstance()
    .put(`${LECTURE_PATH}/${lectureId}/resource`, data)
    .then((res) => res.data);
};

export const deleteLectureResource = async (
  data: string[],
  lectureId: string
) => {
  return getInstance()
    .delete(`${LECTURE_PATH}/${lectureId}/resource`, { data })
    .then((res) => res.data);
};

export const createPartialLecture = async (
  data: TPartialLecturePayload,
  courseId: string
) => {
  return getInstance()
    .post<TLecture>(`${BASE_PATH}/${courseId}/lecture`, data)
    .then((res) => res.data);
};

export const createLecture = async (data: TLecture, courseId: string) => {
  return getInstance()
    .post<TLecture>(`${BASE_PATH}/${courseId}/lecture`, data)
    .then((res) => res.data);
};

export const updateLecture = async (
  lectureId: string,
  data: Partial<TLecture>,
  courseId: string
) => {
  return getInstance()
    .put<TLecture>(`${BASE_PATH}/${courseId}/lecture/id/${lectureId}`, data)
    .then((res) => res.data);
};

export const deleteLecture = async (lectureId: string) => {
  return getInstance()
    .delete(`${LECTURE_PATH}/${lectureId}`)
    .then((res) => res.data);
};
