import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const state: { instance?: AxiosInstance } = {};

export const setInstance = (config: AxiosRequestConfig) => {
  state.instance = axios.create(config);
  return state.instance;
};

export const getInstance = () => {
  if (!state.instance) {
    throw new Error("Seeri Api hasn't been initialized");
  }
  return state.instance;
};
