import { getInstance } from "../../core/axios-gateway";
import {
  TResponseMetadataBank,
  TResponseMetadataBrandLimitTypes,
  TResponseMetadataCountry,
  TResponseMetadataDocumentType,
  TResponseMetadataFaqType,
  TResponseMetadataTaxClassification,
  TResponseMetadataTaxType,
} from "../../types/metadata";

const BASE_PATH = "/api/metadata";

export const getMetadataDocumentTypes = async () => {
  return getInstance()
    .get<TResponseMetadataDocumentType>(`${BASE_PATH}/IdTypes`)
    .then((res) => res.data.idTypes);
};

export const getMetadataCountries = async () => {
  return getInstance()
    .get<TResponseMetadataCountry>(`${BASE_PATH}/Countries`)
    .then((res) => res.data.countries);
};

export const getMetadataBanks = async () => {
  return getInstance()
    .get<TResponseMetadataBank>(`${BASE_PATH}/Banks`)
    .then((res) => res.data.bank);
};

export const getMetadataTaxClassifications = async () => {
  return getInstance()
    .get<TResponseMetadataTaxClassification>(`${BASE_PATH}/TaxClassifications`)
    .then((res) => res.data.taxClassifications);
};

export const getMetadataTaxTypes = async () => {
  return getInstance()
    .get<TResponseMetadataTaxType>(`${BASE_PATH}/Taxes`)
    .then((res) => res.data.taxes);
};

export const getMetadataFaqs = async () => {
  return getInstance()
    .get<TResponseMetadataFaqType>(`${BASE_PATH}/FAQ`)
    .then((res) => res.data.faqs);
};

export const getMetadataBrandLimitTypes = async () => {
  return getInstance()
    .get<TResponseMetadataBrandLimitTypes>(`${BASE_PATH}/BrandValues`)
    .then((res) => res.data.limitType);
};
