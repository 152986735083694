import { getInstance } from "../../core/axios-gateway";
import { TResource } from "../../types/education/resource";
import { TRestListData, TRestListParams } from "../../types/rest";

const BASE_PATH = "/api/education/lecture";
const RESOURCE_PATH = "/api/education/resource";

export const getResources = async (
  params: TRestListParams,
  lectureId: string
) => {
  return getInstance()
    .get<TRestListData<TResource>>(`${BASE_PATH}/${lectureId}/resource/list`, {
      params,
    })
    .then((res) => res.data);
};

export const getResource = async (resourceId: string, lectureId: string) => {
  return getInstance()
    .get<TResource>(`${BASE_PATH}/${lectureId}/resource/${resourceId}`)
    .then((res) => res.data);
};

export const createResource = async (data: TResource, lectureId: string) => {
  return getInstance()
    .post<TResource>(`${BASE_PATH}/${lectureId}/resource`, data)
    .then((res) => res.data);
};

export const updateResource = async (
  resourceId: string,
  data: TResource,
  lectureId: string
) => {
  return getInstance()
    .put<TResource>(`${BASE_PATH}/${lectureId}/resource/id/${resourceId}`, data)
    .then((res) => res.data);
};

export const deleteResource = async (resourceId: string) => {
  return getInstance()
    .delete(`${RESOURCE_PATH}/${resourceId}`)
    .then((res) => res.data);
};
