import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";
import { TCreateVoucher, TVoucher } from "../../types/voucher";

const BASE_PATH = "/api/debt/vouchers";

export const createVoucher = async (data: TCreateVoucher) => {
  return getInstance()
    .post<TVoucher>(BASE_PATH, data)
    .then((res) => res.data);
};

export const getVouchers = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TVoucher>>(BASE_PATH, { params })
    .then((res) => res.data);
};

export const getVoucher = async (id: number) => {
  return getInstance()
    .get<TVoucher>(`${BASE_PATH}/${id}`)
    .then((res) => res.data);
};

export const updateVoucherStatus = async (
  id: number,
  data: {
    status: "PENDING" | "APPROVED";
    reference: string;
  }
) => {
  return getInstance()
    .put<TVoucher>(`${BASE_PATH}/${id}/${data.status}`, {
      reference: data.reference,
    })
    .then((res) => res.data);
};

export const deleteVoucher = async (id: number) => {
  return getInstance().delete(`${BASE_PATH}/${id}`);
};
