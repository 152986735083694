import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";
import {
  TSupplierOrder,
  TUpdateSupplierOrderAction,
} from "../../types/supliers-orders";

const BASE_PATH = "/api/sub-purchases";

export const generateRemission = async (id: string) => {
  return getInstance()
    .put<TSupplierOrder>(`${BASE_PATH}/${id}/remission`, {})
    .then((res) => res.data);
};

export const getAllSupplierOrders = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TSupplierOrder>>(`${BASE_PATH}`, {
      params: {
        ...params,
        search: `isFromSupplier:true,${params.search}`,
      },
    })
    .then((res) => res.data);
};

export const getSupplierOrders = async (
  supplierId: string,
  params: TRestListParams
) => {
  return getInstance()
    .get<TRestListData<TSupplierOrder>>(`${BASE_PATH}`, {
      params: {
        ...params,
        search: `supplierId:${supplierId},${params.search}`,
      },
    })
    .then((res) => res.data);
};

export const updateSupplierOrder = async (
  orderId: string,
  action: TUpdateSupplierOrderAction,
  data: Partial<TSupplierOrder> = {}
) => {
  return getInstance()
    .put<TSupplierOrder>(`${BASE_PATH}/${orderId}/${action}`, data)
    .then((res) => res.data);
};

export const getSupplierOrderById = async (orderId: string) => {
  return getInstance()
    .get<TSupplierOrder>(`${BASE_PATH}/id/${orderId}`)
    .then((res) => res.data);
};

export const updateTrackingCodeSupplierOrder = async (
  orderId: string,
  trackingCode: string,
  trackingFile?: string
) => {
  return getInstance()
    .put<TSupplierOrder>(`${BASE_PATH}/${orderId}/manual`, {
      trackingCode,
      trackingFile,
    })
    .then((res) => res.data);
};

export const splitSupplierOrder = async (orderId: string, data: string[]) => {
  return getInstance()
    .put<TSupplierOrder>(`${BASE_PATH}/${orderId}/split`, data)
    .then((res) => res.data);
};

export const createProcurementSupplierOrder = async (
  supplierId: string,
  products: { id: string; quantity: number }[]
) => {
  return getInstance()
    .post<TSupplierOrder>(`${BASE_PATH}/procurement`, { supplierId, products })
    .then((res) => res.data);
};
