import { AxiosRequestHeaders } from "axios";

import { getInstance } from "../../core/axios-gateway";
import { TProductGroup } from "../../types/product-group";
import { TRestListData, TRestListParams } from "../../types/rest";

const BASE_PATH = "/api/products/v2/group";

export const getProductGroups = async (
  params: TRestListParams,
  headers?: AxiosRequestHeaders
) =>
  getInstance()
    .get<TRestListData<TProductGroup>>(BASE_PATH, {
      params,
      headers,
    })
    .then((response) => response.data);

export const getProductGroup = async (id: string) =>
  getInstance()
    .get<TProductGroup>(BASE_PATH + "/" + id)
    .then((response) => response.data);

export const createProductGroup = async (data: any) =>
  getInstance()
    .post<TProductGroup>(BASE_PATH, data)
    .then((response) => response.data);

export const updateProductGroup = (id: string, data: any) =>
  getInstance()
    .put<TProductGroup>(BASE_PATH + "/" + id, data)
    .then((response) => response.data);

export const deleteProductGroup = async (id: string) =>
  getInstance()
    .delete(BASE_PATH + "/" + id)
    .then((response) => response.data);
