import { getInstance } from "../../core/axios-gateway";
import { TCoupon, TCouponType } from "../../types/coupon";
import { TRestListData, TRestListParams } from "../../types/rest";

const BASE_PATH = "/api/coupons";

export const getCouponTypes = async () => {
  return getInstance()
    .get<TCouponType[]>(`${BASE_PATH}/couponTypes`)
    .then((res) => res.data);
};

export const getCoupons = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TCoupon>>(BASE_PATH, { params })
    .then((res) => res.data);
};

export const getCouponById = async (couponId: string) => {
  return getInstance()
    .get<TCoupon>(`${BASE_PATH}/id/${couponId}`)
    .then((res) => res.data);
};

export const getCouponByCode = async (couponCode: string) => {
  return getInstance()
    .get<TCoupon>(`${BASE_PATH}/code/${couponCode}`)
    .then((res) => res.data);
};

export const createCoupon = async (coupon: TCoupon) => {
  return getInstance()
    .post<TCoupon>(BASE_PATH, coupon)
    .then((res) => res.data);
};

export const updateCoupon = async (couponId: string, data: TCoupon) => {
  return getInstance()
    .put<TCoupon>(`${BASE_PATH}/id/${couponId}`, data)
    .then((res) => res.data);
};
