import { getInstance } from "../../core/axios-gateway";
import { TCoinsBalance } from "../../types/balance";
import { TCoinTransaction, TCoinTransactionRevert } from "../../types/coin";
import { TRestListData, TRestListParams } from "../../types/rest";

const BASE_PATH = "/api/coins";

export const bulkChargeCoins = async (file: File, adminId: string) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("createdBy", adminId);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return getInstance()
    .post(`${BASE_PATH}/create-transactions`, formData, config)
    .then((res) => res.data);
};

export const getCoinsTransactionsBySellerId = async (
  sellerId: string,
  params: TRestListParams = {}
) => {
  params = { sort: "number,DESC", ...params };
  return getInstance()
    .get<TRestListData<TCoinTransaction>>(
      `${BASE_PATH}/${sellerId}/transactions`,
      { params }
    )
    .then((res) => res.data);
};

export const getCoinsBalanceBySellerId = async (sellerId: string) => {
  return getInstance()
    .get<TCoinsBalance>(`${BASE_PATH}/${sellerId}/balance`)
    .then((res) => res.data);
};

export const createCoinsTransaction = async (
  sellerId: string,
  data: TCoinTransaction
) => {
  return getInstance()
    .post<TCoinTransaction>(`${BASE_PATH}/${sellerId}/transactions`, data)
    .then((res) => res.data);
};

export const revertCoinsTransaction = async (
  sellerId: string,
  purchaseId: string,
  data: TCoinTransactionRevert
) => {
  return getInstance()
    .post<TCoinTransaction>(
      `${BASE_PATH}/${sellerId}/transactions/${purchaseId}/revert`,
      data
    )
    .then((res) => res.data);
};
