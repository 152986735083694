import {
  getDeliveryProviders,
  getPurchaseById,
  TPurchaseProduct,
  TSubPurchase,
} from "@/api";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

export const useDeliveryProviders = () => {
  const { data: deliveryProviders = [] } = useSWRImmutable(
    "deliveryProviders",
    getDeliveryProviders
  );
  return deliveryProviders;
};

export function groupByPending(products: TPurchaseProduct[]) {
  return products.reduce(
    function (
      memo: {
        [key: string]: TPurchaseProduct[];
      },
      currentValue
    ) {
      if (currentValue.isLocked && !currentValue.subPurchaseId) {
        memo["PREPARING"].push(currentValue);
      }
      if (!currentValue.isLocked && !currentValue.subPurchaseId) {
        memo["PENDING"].push(currentValue);
      }
      return memo;
    },
    {
      PENDING: [],
      PREPARING: [],
    }
  );
}

export function groupInvoicesBySuppurchase(subpurchases: TSubPurchase[]) {
  return subpurchases.reduce(function (
    memo: {
      [key: string]: number;
    },
    currentValue
  ) {
    memo[currentValue.id] = currentValue?.invoices?.length || 0;
    return memo;
  }, {});
}

/**
 * @param {TPurchaseDeliveryStatus} deliveryStatus Delivery status code
 * @returns {string} Delivery status name, default -
 */
export const formatPurchaseDeliveryStatus = (deliveryStatus: string) => {
  const logPurchaseDeliveryStatus: { [key: string]: () => string } = {
    PENDING: () => "Pendiente",
    PREPARATION: () => "En preparación",
    IN_TRANSIT: () => "En camino",
    DELIVERED: () => "Entregado",
    REJECTED: () => "Rechazado",
    FAILED_TO_SEND: () => "Fallo al enviar",
    DEFAULT: () => "-",
  };
  return (
    logPurchaseDeliveryStatus[deliveryStatus]?.() ||
    logPurchaseDeliveryStatus["DEFAULT"]()
  );
};

/**
 * @param {string} status status code
 * @returns {string} status color name
 */
export const formatStatusColor = (status: string) => {
  const logStatusColor: {
    [key: string]: () =>
      | "primary"
      | "secondary"
      | "default"
      | "success"
      | "error"
      | "info"
      | "warning";
  } = {
    PENDING: () => "default",
    PREPARATION: () => "info",
    PREPARING: () => "info",
    IN_TRANSIT: () => "info",
    IN_PROCESS: () => "info",
    DELIVERED: () => "success",
    VALIDATED: () => "success",
    REJECTED: () => "warning",
    FAILED_TO_SEND: () => "error",
    CANCELLED: () => "error",
    NOT_VALID: () => "error",
    PAID: () => "success",
    DONE: () => "success",
    DEFAULT: () => "default",
  };
  return logStatusColor[status]?.() || logStatusColor["DEFAULT"]();
};

export function groupTotalByStatus(products: any[]) {
  return products.reduce(
    function (
      memo: {
        [key: string]: number;
      },
      currentValue
    ) {
      if (!currentValue.subPurchaseId) {
        memo["PENDING"] = memo["PENDING"] + currentValue.subTotalWithDiscount;
      }
      return memo;
    },
    {
      PENDING: 0,
    }
  );
}

export function groupTotalBySubpurchaseStatus(subPurchases: TSubPurchase[]) {
  return subPurchases.reduce(function (
    memo: {
      [key: string]: number;
    },
    currentValue: any
  ) {
    if (typeof memo[currentValue.deliveryStatus] !== "number") {
      memo[currentValue.deliveryStatus] = 0;
    }

    var subPurchaseTotal = currentValue.products.reduce(function (
      memoSum: number,
      product: any
    ) {
      return memoSum + (product.subTotalWithDiscount ?? 0);
    },
    0);

    memo[currentValue.deliveryStatus] =
      memo[currentValue.deliveryStatus] + subPurchaseTotal;

    return memo;
  }, {});
}

export const usePurchaseProducts = (products: any[], orderId: string) => {
  const { data: purchase } = useSWR([orderId, "purchaseId"], getPurchaseById);
  const couponType = purchase?.couponInfo?.type;

  let orderProducts = products ?? [];
  if (purchase?.products?.length) {
    const purchaseProducts = purchase.products;
    orderProducts =
      orderProducts?.map((product) => ({
        ...(purchaseProducts.find(({ id }) => product.productId === id) ||
          product),
        supplierSku: product.sku,
        productId: product.productId,
        couponType,
      })) ?? [];
  }

  return orderProducts;
};
