import { AxiosRequestHeaders } from "axios";

import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";
import { TProduct, TSearchByText } from "../../types/product";

const BASE_PATH = "/api/products/v2";

export const getProductsV2 = async (
  params: TRestListParams,
  headers?: AxiosRequestHeaders
) => {
  return getInstance()
    .get<TRestListData<TProduct>>(BASE_PATH, { params, headers })
    .then((res) => res.data);
};

export const getProductsByIdsV2 = async (ids: string[]) => {
  const opts = {
    headers: { "x-log": "omit" },
  };
  return getInstance()
    .post<TProduct[]>("/api/products/by-ids/v2", { ids }, opts)
    .then((res) => res.data);
};

export const getProductV2 = async (id: string, params?: any) => {
  return getInstance()
    .get<TProduct>(`${BASE_PATH}/${id}`, { params })
    .then((res) => res.data);
};

export const createProductV2 = async (data: TProduct) => {
  return getInstance()
    .post<TProduct>(BASE_PATH, data)
    .then((res) => res.data);
};

export const updateProductV2 = async (id: string, data: TProduct) => {
  return getInstance()
    .put<TProduct>(`${BASE_PATH}/${id}`, data)
    .then((res) => res.data);
};

export const deleteProductV2 = async (id: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/${id}`)
    .then((res) => res.data);
};

export const updateProductV2ProductGroup = async (
  id: string,
  productGroupId: string
) => {
  return getInstance()
    .put<TProduct>(`${BASE_PATH}/parent-change`, {
      productId: id,
      groupProductId: productGroupId,
    })
    .then((res) => res.data);
};

export const searchProducts = async (body: {
  data: TSearchByText;
  params?: TRestListParams;
  headers?: AxiosRequestHeaders;
}) => {
  return getInstance()
    .post<TRestListData<TProduct>>(`${BASE_PATH}/search`, body.data, {
      params: body.params,
      headers: body.headers,
    })
    .then((res) => res.data);
};
