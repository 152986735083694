import { getInstance } from "../../core/axios-gateway";
import { TPurchase, TRequestPurchaseCancellation } from "../../types/purchase";

const BASE_PATH = "/api/cancellation";

export const getPurchaseCancellationReasons = async (userType = "") => {
  return getInstance()
    .get<string[]>(`${BASE_PATH}/reasonCancelOrder`, { params: { userType } })
    .then((res) => res.data);
};

export const cancelPurchase = async (
  purchaseId: string,
  data: TRequestPurchaseCancellation
) => {
  return getInstance()
    .post<TPurchase>(`${BASE_PATH}/${purchaseId}`, data)
    .then((res) => res.data);
};
