import { getInstance } from "../../core/axios-gateway";
import {
  TAccountGroup,
  TJoinInvoiceSubPurchase,
  TSupplierAccountGroup,
} from "../../types/siigo";

const BASE_PATH = "/api/siigo";

export const getAccountGroups = async () => {
  return getInstance()
    .get<TAccountGroup[]>(`${BASE_PATH}/account-groups`)
    .then((res) => res.data);
};

export const getAccountGroupsSupplier = async () => {
  return getInstance()
    .get<TSupplierAccountGroup[]>(
      `${BASE_PATH}/account-groups/siigoAccountGroups`
    )
    .then((res) => res.data);
};

export const createAccountGroup = async (data: TSupplierAccountGroup) => {
  return getInstance()
    .post<TSupplierAccountGroup>(
      `${BASE_PATH}/account-groups/siigoAccountGroups`,
      data
    )
    .then((res) => res.data);
};

export const generateInvoiceBySubpurchaseIds = async (
  subPurchaseIds: string[]
) => {
  return getInstance()
    .post(`${BASE_PATH}/invoices/notify/creation/sub-purchase/on-demand`, {
      ids: subPurchaseIds,
    })
    .then((res) => res.data);
};

export const getPdfInvoice = async (invoiceId: string) => {
  return getInstance()
    .get(`${BASE_PATH}/invoices/${invoiceId}/pdf`)
    .then((res) => res.data);
};

export const joinInvoiceSubPurchase = async (data: TJoinInvoiceSubPurchase) => {
  return getInstance()
    .post(`${BASE_PATH}/invoices/join-invoice-sub-purchase`, data)
    .then((res) => res.data);
};
