export const status = [
  { name: "Pendiente", code: "PENDING" },
  { name: "En proceso", code: "IN_PROCESS" },
  { name: "En camino", code: "IN_TRANSIT" },
  { name: "Entregado", code: "DELIVERED" },
  { name: "Realizado", code: "DONE" },
  { name: "Cancelado", code: "CANCELLED" },
  { name: "Expirado", code: "EXPIRED" },
  { name: "Rechazado", code: "REJECTED" },
  { name: "Fallido", code: "FAILED" },
  { name: "Cotización", code: "QUOTED" },
];

export const supplierStatus = [
  { name: "Pendiente", code: "PENDING" },
  { name: "Aprobado", code: "APPROVED" },
  { name: "Rechazado", code: "REJECTED" },
  { name: "Cancelado", code: "CANCELLED" },
  { name: "En proceso", code: "IN_PROCESS" },
  { name: "Remisión generada", code: "REMISSION_GENERATED" },
];

export const deliveryStatus = [
  { name: "Pendiente", code: "PENDING" },
  // { name: "Fallido", code: "FAILED" },
  { name: "En preparación", code: "PREPARATION" },
  // { name: "En alistamiento", code: "PREPARING" },
  { name: "En camino", code: "IN_TRANSIT" },
  { name: "En procesamiento", code: "IN_PROCESS" },
  { name: "Entregado", code: "DELIVERED" },
  { name: "Rechazado", code: "REJECTED" },
  { name: "Cancelado", code: "CANCELLED" },
  { name: "Cotizacion", code: "QUOTED" },
  { name: "Pendiente", code: "SUPPLIER_PENDING" },
  // { name: "Fallo al enviar", code: "FAILED_TO_SEND" },
  // { name: "Enviado desde el proveedor", code: "PICKUP_FROM_SUPPLIER" },
  // { name: "Devuelta a proveedor", code: "DELIVERED_TO_SUPPLIER" },
  // { name: "Rechazado por proveedor", code: "SUPPLIER_REJECTED" },
];

export const deliveryStatusRendering = [
  { name: "Pendiente", code: "PENDING" },
  { name: "Fallido", code: "FAILED" },
  { name: "En preparación", code: "PREPARATION" },
  { name: "En alistamiento", code: "PREPARING" },
  { name: "En camino", code: "IN_TRANSIT" },
  { name: "En procesamiento", code: "IN_PROCESS" },
  { name: "Entregado", code: "DELIVERED" },
  { name: "Rechazado", code: "REJECTED" },
  { name: "Cancelado", code: "CANCELLED" },
  { name: "Fallo al enviar", code: "FAILED_TO_SEND" },
  { name: "Enviado desde el proveedor", code: "PICKUP_FROM_SUPPLIER" },
  { name: "Devuelta a proveedor", code: "DELIVERED_TO_SUPPLIER" },
  { name: "Rechazado por proveedor", code: "SUPPLIER_REJECTED" },
  { name: "Pendiente por proveedor", code: "SUPPLIER_PENDING" },
  { name: "Remisión generada", code: "REMISSION_GENERATED" },
  { name: "Aprobado", code: "APPROVED" },
];

export const paymentStatus = [
  { name: "Pendiente", code: "PENDING" },
  { name: "Por revisar", code: "REVIEW" },
  { name: "Pagado", code: "PAID" },
  { name: "Fallido", code: "FAILED" },
  { name: "Expirado", code: "EXPIRED" },
  { name: "Cancelado", code: "CANCELLED" },
];

export const linkStatus = [
  { name: "Pendiente", code: "PENDING" },
  { name: "Compartido", code: "SHARED" },
  { name: "Abierto", code: "OPENED" },
  { name: "Expirado", code: "EXPIRED" },
  { name: "Sin enlace", code: "NO_LINK" },
];

export const quoterUserType = [
  { name: "Vendedor", code: "SELLER" },
  { name: "Tienda", code: "STORE" },
];

export const ticketRefundTypes = [
  { name: "Manual", code: "MANUAL" },
  { name: "SeeriCoins", code: "COINS" },
  { name: "Ninguno", code: "NONE" },
  { name: "Reponer Producto", code: "REPLENISH_PRODUCTS" },
  { name: "No aplicable a proveedor", code: "NOT_APPLICABLE_FOR_SUPPLIER" },
];

export const ticketStatuses = [
  { name: "Pendiente", code: "PENDING" },
  { name: "Rechazado", code: "REJECTED" },
  { name: "Aprobado", code: "APPROVED" },
  { name: "Pendiente por proveedor", code: "IN_SUPPLIER_REVIEW" },
  { name: "En revisión", code: "IN_REVIEW" },
];

export const ticketTypes = [
  { name: "Reembolso", code: "REFUND" },
  { name: "Devolución", code: "RETURN" },
];

export const ticketReasons = [
  { name: "Mi pedido no ha llegado", code: "ORDER_WITHOUT_ARRIVING" },
  { name: "Mi pedido llegó incompleto", code: "INCOMPLETE_ORDER" },
  { name: "Productos defectuosos", code: "DEFECTIVE_PRODUCTS" },
  { name: "Productos erróneos", code: "WRONG_PRODUCTS" },
];

export const invoiceTypes = [
  { name: "Factura", code: "INVOICE" },
  { name: "Nota de Crédito Parcial", code: "PARTIAL_CREDIT_NOTE" },
  { name: "Nota de Crédito Total", code: "TOTAL_CREDIT_NOTE" },
];
