// Flags: https://flagpedia.net/download/api 32x24

import { isProd } from "../constants/env";
import coFlag from "../public/navbar/co.png";
import mxFlag from "../public/navbar/mx.png";
import usFlag from "../public/navbar/us.png";
import { BANNERS, COURSES, DOCUMENTS, TICKETS } from "./resources";

export type TCountryCode = "co" | "mx" | "us";

export type TAppCountry = {
	id: string;
	code: TCountryCode;
	microserviceHost: TCountryCode;
	name: string;
	phoneCode: string;
	locale: string;
	currency: string;
	flag: string;
	hostname: string;
	hiddenResources?: string[];
};

const CO: TAppCountry = {
	id: "48",
	code: "co",
	microserviceHost: "co",
	name: "Colombia",
	phoneCode: "+57",
	locale: "es-CO",
	currency: "COP",
	flag: coFlag,
	hostname: isProd ? "backoffice.seeri.co" : "backoffice.dev.seeri.co",
};

const MX: TAppCountry = {
	id: "142",
	code: "mx",
	microserviceHost: "co",
	name: "Mexico",
	phoneCode: "+52",
	locale: "es-MX",
	currency: "MXN",
	flag: mxFlag,
	hostname: isProd ? "backoffice.mx.seeri.co" : "backoffice.mx.dev.seeri.co",
};

const US: TAppCountry = {
	id: "233",
	code: "us",
	microserviceHost: "co",
	name: "United States",
	phoneCode: "+1",
	locale: "es-US",
	currency: "USD",
	flag: usFlag,
	hostname: isProd ? "backoffice.us.seeri.co" : "backoffice.us.dev.seeri.co",
	hiddenResources: [BANNERS, DOCUMENTS, TICKETS, COURSES],
};

export const countries: TAppCountry[] = [CO, MX, US];

export const phonePrefixChoices = [CO, MX, US].map((c) => ({
	id: c.phoneCode,
	name: `${c.name} (${c.phoneCode})`,
}));

export const getCountryByUrl = (): TAppCountry => {
	let country = CO;

	if (typeof window !== "undefined") {
		country = countries.find((c) => c.hostname === location.hostname) || CO;
	}

	return country;
};

export const country = getCountryByUrl();
