import { getInstance } from "../../core/axios-gateway";
import { TDebtSummary, TDebtTransaction } from "../../types/debt";
import { TRestListData, TRestListParams } from "../../types/rest";

export const getDebtSummary = async (sellerId: string) => {
  return getInstance()
    .get<TDebtSummary>("/api/debt/" + sellerId)
    .then((res) => res.data);
};

export const getDebtTransactions = async (params: TRestListParams = {}) => {
  return getInstance()
    .get<TRestListData<TDebtTransaction>>("/api/debt/transactions", { params })
    .then((res) => res.data);
};
