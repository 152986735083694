import { getInstance } from "../../core/axios-gateway";
import { TCourse, TPartialCoursePayload } from "../../types/education/course";
import { TRestListData, TRestListParams } from "../../types/rest";

const BASE_PATH = "/api/education/course";

export const getCourses = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TCourse>>(`${BASE_PATH}/list`, { params })
    .then((res) => res.data);
};

export const getCourse = async (courseId: string) => {
  return getInstance()
    .get<TCourse>(`${BASE_PATH}/${courseId}`)
    .then((res) => res.data);
};

export const createPartialCourse = async (data: TPartialCoursePayload) => {
  return getInstance()
    .post<TCourse>(BASE_PATH, data)
    .then((res) => res.data);
};

export const createCourse = async (data: TCourse) => {
  return getInstance()
    .post<TCourse>(BASE_PATH, data)
    .then((res) => res.data);
};

export const updateCourse = async (
  courseId: string,
  data: Partial<TCourse>
) => {
  return getInstance()
    .put<TCourse>(`${BASE_PATH}/id/${courseId}`, data)
    .then((res) => res.data);
};

export const deleteCourse = async (courseId: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/${courseId}`)
    .then((res) => res.data);
};
