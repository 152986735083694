import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";
import { TBanner } from "../../types/banner";

const BASE_PATH = "/api/banners";

export const createBanner = async (data: TBanner) => {
  return getInstance()
    .post<TBanner>(BASE_PATH, data)
    .then((res) => res.data);
};

export const getBanners = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TBanner>>(BASE_PATH, { params })
    .then((res) => res.data);
};

export const getBanner = async (id: string) => {
  return getInstance()
    .get<TBanner>(`${BASE_PATH}/${id}`)
    .then((res) => res.data);
};

export const updateBanner = async (id: string, data: TBanner) => {
  return getInstance()
    .put<TBanner>(`${BASE_PATH}/${id}`, data)
    .then((res) => res.data);
};

export const deleteBanner = async (id: string) => {
  return getInstance().delete(`${BASE_PATH}/${id}`);
};

export const getSectionBanner = async (section: string, id: string) => {
  const url = !id
    ? `${BASE_PATH}/section/${section}`
    : `${BASE_PATH}/section/${section}/id/${id}`;
  return getInstance()
    .get(url)
    .then((res) => res.data);
};
