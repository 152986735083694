import { TRestListData, TRestListParams } from "@/api/types/rest";
import { getInstance } from "../../core/axios-gateway";

export interface TSupplierPayment {
  id: number;
  supplierId: string;
  supplierName: string;
  paymentNumber: number;
  total: number;
  status: string;
  cutoffDate: string;
  createdAt: string;
  transactions: { [key: string]: TSupplierPaymentTransaction[] };
}

export interface TSupplierPaymentTransactionPaymentEntity {
  id: number;
  supplierId: string;
  paymentNumber: number;
  total: number;
  status: string;
  createdAt: string;
  transactions: TSupplierPaymentTransaction[];
}

export interface TSupplierPaymentTransaction {
  id: number;
  type: TSupplierPaymentTransactionType;
  reference: string;
  subPurchaseId: string;
  subPurchaseNumber: string;
  supplierId: string;
  paymentEntity?: TSupplierPaymentTransactionPaymentEntity;
  value: number;
  data: TSupplierPaymentTransactionData | null;
  createdAt: null;
}

export interface TSupplierPaymentTransactionData {
  quantity: number;
  name: string;
  sellPrice: number;
  subTotal: number;
  sku: string;
}

export enum TSupplierPaymentTransactionType {
  PaymentService = "PAYMENT_SERVICE",
  LogisticService = "LOGISTIC_SERVICE",
  ProductSell = "PRODUCT_SELL",
}

export const getSupplierPayments = async (
  supplierId: string,
  params: TRestListParams
) => {
  return getInstance()
    .get<TRestListData<TSupplierPayment>>(
      `/api/suppliers/payment/list/${supplierId}`,
      { params }
    )
    .then((res) => res.data);
};

export const getSupplierPayment = async (paymentId: string) => {
  return getInstance()
    .get<TSupplierPayment>(`/api/suppliers/payment/${paymentId}`)
    .then((res) => res.data);
};

export const generateSupplierPayment = async (
  supplierId: string,
  cutoffDate: string
) => {
  return getInstance()
    .post(`/api/suppliers/payment/${supplierId}`, { cutoffDate })
    .then((res) => res.data);
};

export const generateSupplierPaymentAndDownloadExcel = async (
  supplierId: string,
  cutoffDate: string
) => {
  return getInstance()
    .post(
      `/api/reports/supplier-payment/${supplierId}`,
      { cutoffDate },
      { responseType: "blob" }
    )
    .then((res) => {
      const href = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "payment.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

export const updateSupplierPaymentStatus = async (
  paymentId: number,
  status: "PENDING" | "PAID"
) => {
  return getInstance()
    .put(`/api/suppliers/payment/${paymentId}`, { status })
    .then((res) => res.data);
};

export const deleteSupplierPayment = async (paymentId: number) => {
  return getInstance()
    .delete(`/api/suppliers/payment/${paymentId}`)
    .then((res) => res.data);
};

export const getSupplierPaymentExcel = async (paymentId: number) => {
  return getInstance()
    .get(`/api/reports/supplier-payment/payment/${paymentId}`, {
      responseType: "blob",
    })
    .then((res) => {
      const href = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "payment.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

export const getSupplierPendingTransactions = async (
  supplierId: string,
  params: TRestListParams
) => {
  return getInstance()
    .get(`/api/suppliers/transaction/list/${supplierId}`, { params })
    .then((res) => res.data);
};
