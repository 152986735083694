import { getInstance } from "../../core/axios-gateway";
import { TSendOtpPayload, TVerifyOtpPayload } from "../../types/twilio";

const BASE_PATH = "/api/twilio";

export const sendOtp = async (data: TSendOtpPayload) => {
  return getInstance()
    .post(`${BASE_PATH}/send_otp`, data)
    .then((res) => res.data);
};

export const verifyOtp = async (data: TVerifyOtpPayload) => {
  return getInstance()
    .post(`${BASE_PATH}/verify_otp`, data)
    .then((res) => res.data);
};
