import { getInstance } from "../../core/axios-gateway";
import { TBrand } from "../../types/brand";
import { TRestListData, TRestListParams } from "../../types/rest";

const BASE_PATH = "/api/brands";

export const getBrands = async (params: TRestListParams = {}) => {
  return getInstance()
    .get<TRestListData<TBrand>>(`${BASE_PATH}/list`, { params })
    .then((res) => res.data);
};

export const getBrandById = async (brandId: string) => {
  return getInstance()
    .get<TBrand>(`${BASE_PATH}/${brandId}`)
    .then((res) => res.data);
};

export const getBrandsBySupplierId = async (supplierId: string) => {
  return getInstance()
    .get<TBrand[]>(`${BASE_PATH}/by-supplier-id/${supplierId}`)
    .then((res) => res.data);
};

export const createBrand = async (data: TBrand) => {
  return getInstance()
    .post<TBrand>(BASE_PATH, data)
    .then((res) => res.data);
};

export const updateBrand = async (brandId: string, data: TBrand) => {
  return getInstance()
    .put<TBrand>(`${BASE_PATH}/id/${brandId}`, data)
    .then((res) => res.data);
};
