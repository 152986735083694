import { Identifier, RaRecord } from "react-admin";
import { ListItemText, MenuItem } from "@mui/material";
import * as XLSX from "xlsx";
import { TPurchaseProduct } from "@/api";
import { formatCurrency } from "@/utils/currency";
import { groupByPending } from "@/hooks/purchase";
import { deliveryStatus, paymentStatus } from "@/data/purchases";

const DATA_OFFSET = 12;

type ExportXLSXMenuItem = {
  setState: (value: any) => void;
  record: RaRecord<Identifier>;
};

export const ExportXLSXMenuItem: React.FC<ExportXLSXMenuItem> = ({
  record,
  setState,
}) => {
  return (
    <>
      <MenuItem
        onClick={() => {
          setState(null);
          exportRemission(record);
        }}
      >
        <ListItemText>Descargar Excel</ListItemText>
      </MenuItem>
    </>
  );
};

const exportRemission = (purchase: any) => {
  const { PENDING, PREPARING } = groupByPending(purchase?.products || []);
  let products: any = {};
  products = {
    ...products,
    ...getProductsRows(PENDING, DATA_OFFSET, "Productos pendientes"),
  };
  products = {
    ...products,
    ...getProductsRows(
      PREPARING,
      DATA_OFFSET + PENDING.length + 1,
      "Productos en alistamiento por proveedor"
    ),
  };
  let subPurchaseOffset = DATA_OFFSET + PENDING.length + PREPARING.length + 2;
  purchase?.subPurchases?.forEach((subpurchase: any) => {
    products = {
      ...products,
      ...getSubpurchaseRows(subPurchaseOffset, subpurchase),
    };
    subPurchaseOffset += 7;

    products = {
      ...products,
      ...getProductsRows(subpurchase?.products, subPurchaseOffset, ""),
    };
    subPurchaseOffset += subpurchase?.products?.length + 1;

    products[`E${subPurchaseOffset}`] = { t: "s", v: "TOTAL" };
    products[`F${subPurchaseOffset}`] = {
      t: "s",
      v: formatCurrency(
        subpurchase.products.reduce(
          (previousValue: number, currentValue: TPurchaseProduct) =>
            previousValue + (currentValue?.subTotalWithDiscount ?? 0),
          0
        )
      ),
    };

    subPurchaseOffset += 1;
  });

  XLSX.writeFile(
    {
      SheetNames: ["Bogota"],
      Sheets: {
        Bogota: {
          "!ref": "A1:F100",
          A1: { t: "s", v: "CEDULA" },
          B1: { t: "s", v: purchase?.client?.documentId },
          A2: { t: "s", v: "NUMERO DE PEDIDO" },
          B2: { t: "s", v: purchase?.purchaseNumber },
          A3: { t: "s", v: "NOMBRE" },
          B3: { t: "s", v: purchase?.client?.displayName },
          A4: { t: "s", v: "CIUDAD" },
          B4: { t: "s", v: purchase?.client?.location?.city },
          A5: { t: "s", v: "ESTADO" },
          B5: { t: "s", v: purchase?.client?.location?.state },
          A6: { t: "s", v: "DIRECCIÓN" },
          B6: { t: "s", v: purchase?.client?.location?.addressLine1 },
          A7: { t: "s", v: "TELEFONO" },
          B7: { t: "s", v: purchase?.client?.phone },
          A8: { t: "s", v: "CORREO ELECTRÓNICO" },
          B8: { t: "s", v: purchase?.client?.email },
          A9: { t: "s", v: "CANTIDAD DE CAJAS" },
          B9: { t: "s", v: " " },
          ...products,
          ...getTotalRows(subPurchaseOffset, purchase?.total),
          "!cols": [
            { wch: 28 },
            { wch: 40 },
            { wch: 12 },
            { wch: 12 },
            { wch: 12 },
            { wch: 12 },
          ],
          "!merges": [
            { s: { r: 0, c: 1 }, e: { r: 0, c: 2 } },
            { s: { r: 1, c: 1 }, e: { r: 1, c: 2 } },
            { s: { r: 2, c: 1 }, e: { r: 2, c: 2 } },
            { s: { r: 3, c: 1 }, e: { r: 3, c: 2 } },
            { s: { r: 4, c: 1 }, e: { r: 4, c: 2 } },
            { s: { r: 5, c: 1 }, e: { r: 5, c: 2 } },
            { s: { r: 6, c: 1 }, e: { r: 6, c: 2 } },
            { s: { r: 7, c: 1 }, e: { r: 7, c: 2 } },
            { s: { r: 8, c: 1 }, e: { r: 8, c: 2 } },
            ...getTotalRowsMerge(subPurchaseOffset),
          ],
        },
      },
    },
    `remisión_${purchase?.id}.xlsx`
  );
};

const getProductsRows = (
  products: any[] = [],
  offset: number,
  initialText = ""
) => {
  let productsRows: any = {};
  if (products.length) {
    let initialRow = offset;
    if (initialText) {
      productsRows[`A${offset}`] = { t: "s", v: initialText };
      initialRow++;
    }
    products.forEach((product, index) => {
      const row = initialRow + index;
      productsRows[`A${row}`] = { t: "s", v: product?.sku };
      productsRows[`B${row}`] = { t: "s", v: product?.name };
      productsRows[`C${row}`] = { t: "n", v: product?.quantity };
      productsRows[`D${row}`] = {
        t: "s",
        v: formatCurrency(product?.wholesalePrice),
      };
      productsRows[`E${row}`] = {
        t: "s",
        v: formatCurrency(product?.subtotal),
      };
      productsRows[`F${row}`] = {
        t: "s",
        v: formatCurrency(product?.subTotalWithDiscount),
      };
    });
  }

  return productsRows;
};

const getTotalRows = (productsQuantity = 0, total = 0) => {
  let totalRows: any = {};
  const rowStart = productsQuantity + 2;
  totalRows[`A${rowStart}`] = { t: "s", v: "VALOR A COBRAR" };
  totalRows[`A${rowStart + 1}`] = { t: "s", v: formatCurrency(total) };
  totalRows[`A${rowStart + 3}`] = { t: "s", v: "FIRMA" };

  return totalRows;
};

const getSubpurchaseRows = (offset = DATA_OFFSET, subPurchase: any) => {
  let subpurchaseRows: any = {};
  let rowStart = offset;
  rowStart++;
  subpurchaseRows[`A${rowStart}`] = { t: "s", v: "Subpedido No" };
  subpurchaseRows[`B${rowStart}`] = {
    t: "s",
    v: subPurchase?.subPurchaseNumber,
  };
  rowStart++;
  subpurchaseRows[`A${rowStart}`] = { t: "s", v: "Fecha" };
  subpurchaseRows[`B${rowStart}`] = {
    t: "s",
    v: new Date(subPurchase?.createdAt).toLocaleDateString("es"),
  };
  rowStart++;
  subpurchaseRows[`A${rowStart}`] = { t: "s", v: "Guía" };
  subpurchaseRows[`B${rowStart}`] = {
    t: "s",
    v: subPurchase?.trackingCode,
  };
  rowStart++;
  subpurchaseRows[`A${rowStart}`] = { t: "s", v: "Estado de envío" };
  subpurchaseRows[`B${rowStart}`] = {
    t: "s",
    v: deliveryStatus.find((s) => s.code === subPurchase?.deliveryStatus)?.name,
  };
  rowStart++;
  subpurchaseRows[`A${rowStart}`] = { t: "s", v: "Estado de pago" };
  subpurchaseRows[`B${rowStart}`] = {
    t: "s",
    v: paymentStatus.find((s) => s.code === subPurchase?.paymentStatus)?.name,
  };
  rowStart++;
  subpurchaseRows[`A${rowStart}`] = { t: "s", v: "SKU" };
  subpurchaseRows[`B${rowStart}`] = { t: "s", v: "NOMBRE DEL PRODUCTO" };
  subpurchaseRows[`C${rowStart}`] = { t: "s", v: "CANTIDAD" };
  subpurchaseRows[`D${rowStart}`] = { t: "s", v: "PRECIO" };
  subpurchaseRows[`E${rowStart}`] = { t: "s", v: "SUBTOTAL" };
  subpurchaseRows[`F${rowStart}`] = { t: "s", v: "TOTAL" };

  return subpurchaseRows;
};

const getTotalRowsMerge = (offset = 0) => {
  const rowStart = offset + 1;
  return [
    { s: { r: rowStart, c: 0 }, e: { r: rowStart, c: 2 } },
    { s: { r: rowStart + 1, c: 0 }, e: { r: rowStart + 2, c: 2 } },
    { s: { r: rowStart + 3, c: 0 }, e: { r: rowStart + 3, c: 2 } },
    { s: { r: rowStart + 4, c: 0 }, e: { r: rowStart + 7, c: 2 } },
  ];
};
